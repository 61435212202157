@import 'variable.scss';

/*** This file describes the layout of forms, inputs, checkboxes, calendar, etc. ***/

// start form

form {
  width: 100%;
}

.shift-type {
  .form-group {
    margin: 0 !important;
  }

  .has-danger .form-control-feedback {
    position: static !important;
  }
}

.article-modal {
  .has-danger .form-control-feedback {
    position: static !important;
  }

  .custom-fields {
    margin-bottom: -10px !important;
  }
}

.margin-less {
  margin-bottom: -34px;
}

.mb-15 {
  margin-bottom: 15px;
}


.fa-info-circle {
  color: $warm-grey;
  font-size: 15px;
  margin-right: 4px;
}

.article-modal-custom-group {
  background-color: $white-two;
  border-top: 1px solid $white;
  border-bottom: 1px solid $white;
  margin: 10px -10px 0px -10px;
  padding: 10px 0 25px;

  .custom-info {
    margin-bottom: 10px !important;
  }
}

.printer-checkbox {
  margin-left: 15px !important;
  margin-top: -10px !important;
}

.form-group {
  margin-bottom: 30px;

  .select-wrapper {
    .form-control {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

.compact .form-group {
  margin-bottom: 0;
}

.subform {
  background-color: $white-three;
}

input,
.form-control {
  //.form-control überschreibt; mit important wird Suchleiste überschrieben
  background-color: white;
  border: 1px solid $greyish;
  border-radius: 4px;
  color: black;
  font-family: $font;
  font-size: 13px;
  height: 30px;
  padding-left: 6px;

  &:active {
    border: 1px solid $cerulean-blue;
  }

  &:focus {
    border: 1px solid $cerulean-blue;
    outline: none !important;
    box-shadow: none !important;
  }

  &:disabled,
  [read-only] {
    background-color: $white-four;
    color: $greyish-brown;
  }
}

.form-control {
  line-height: 14px;
  padding-right: 17px;
}

.has-danger .form-control-feedback {
  position: absolute;
  padding-top: 6px;
  font-size: 12px;
  color: $vermillion !important;
}

.dropdown-menu .ngb-highlight {
  color: $cerulean-blue !important;
  font-size: 16px;
  font-weight: 400;
}

.form-control-feedback {
  p {
    margin-top: 5px;
    font-size: 12px;
    color: $vermillion;
  }
}

.form-control:disabled,
.form-control[readonly] {
  background-color: $white-four;
}

input:focus,
.form-control:focus {
  box-shadow: none;
}

.active-tick {
  color: $dark-grey !important;
}

.inactive-tick {
  color: $pinkish-grey !important;
}

.success-tick {
  color: $frog-green;
}

.failure-tick {
  color: $vermillion;
}

.text-area-height {
  textarea.form-control {
    height: 100px !important;
  }
}

//placeholder

.form-control::placeholder {
  color: $greyish;
}

.form-control:-ms-input-placeholder {
  color: $greyish;
}

.form-check {
  input[type='checkbox'] {
    margin-top: 2px;
    width: 14px;
    height: 14px;
  }

  span {
    color: $dark-grey;
    font-size: 13px;
  }
}

// Form Validation Login Component
.form-feedback-login {
  position: relative !important;
  padding-top: 3px !important;
}

.separator-top {
  border-top: 2px solid $greyish !important;
}

.separator-bottom {
  border-bottom: 2px solid $white !important;
  margin: 10px -15px 20px -15px;
  padding-bottom: 20px !important;

  .form-group {
    margin-bottom: 6px !important;
  }
}

td.mat-cell .form-group {
  margin-bottom: 0 !important;

  label {
    display: none;
  }
}

// Forms Component Modal

.basevalueInput {
  position: relative;
  top: 8px;
  left: 5px;
}

.basevalueHeadline {
  border-top: 1px solid #dee2e6;

  .headline {
    padding: 10px 0;
    border-bottom: 1px solid #dee2e6;
    display: block;
  }
}

// end form

//-----------------------------------------------------
//-----------------------------------------------------

// start input

.inputBorderShadow {
  border: 1px solid $pinkish-grey;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.16);
}

input[type='checkbox'] {
  border: 1px solid $warm-grey-two;
  border-radius: 2px;
  margin-right: 6px;
  width: 14px;
  height: 14px;
  position: relative;
}

.has-danger input.form-control {
  border-color: $vermillion !important;
}

.has-danger textarea.form-control {
  border-color: $vermillion !important;
}

.has-danger select.form-control {
  border-color: $vermillion !important;
}

.has-success input.form-control {
  border-color: $frog-green !important;
}

.has-danger p {
  color: $vermillion !important;
}

.has-success p {
  color: $frog-green !important;
}

.input {
  width: 306px;
}

.inputs {
  margin-left: 40px;

  input[type='radio'] {
    margin-right: 6px;
  }

  span {
    margin-right: 20px;
    position: relative;
    top: 4px;
  }
}

.input-group {
  flex-wrap: nowrap;

  input {
    height: 30px;
    width: 100px;
    @include respond-to(max-width, 1620px) {
      width: 80px;
    }
    @include respond-to(max-width, 1500px) {
      width: calc(100% - 30px);
    }
  }
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 0;
}

// Overriding bootstrap of type ahead border
.input-group > .form-control:not(:last-child) {
  border-radius: 4px;
}

.input-group.datetime input {
  border-radius: 4px 0 0 4px !important;
}

.start .input-group .input-group-append .calendar,
.start .input-group-append,
.end .input-group-append {
  height: 30px;
  width: 30px;
}

//batch-relation-search: height & width 30px
.input-group-append {
  border-color: $pinkish-grey;
  border-radius: 0 4px 4px 0;
  height: 30px;
  margin: 0;
  width: 27px;

  .shadow {
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.16) !important;
  }
}

.input-group-read-only {
  background-color: $white-four;
  color: $greyish-brown;

  &:after {
    display: none;
  }

  input {
    background-color: $white-four;
    color: $greyish-brown;
  }
}

// end input

//-----------------------------------------------------
//-----------------------------------------------------

// start checkbox and radio button

.checkbox {
  padding-top: 29px;
}

.checkbox label,
.radiobutton label,
.toggle label {
  position: relative;
}

.checkbox label,
.toggle label {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-left: 20px;
}

.checkbox input[type='checkbox'],
.radiobutton input[type='radio'],
.toggle input[type='checkbox'] {
  display: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.checkbox input[type='checkbox'] + label:before,
.checkbox input[type='checkbox'] + span:before {
  background-color: white;
  border: 1px solid $warm-grey-two;
  border-radius: 2px;
  content: '';
  display: inline-block;
  height: 14px;
  margin-right: 6px;
  position: absolute;
  top: 2px;
  left: 0;
  width: 14px;
}

.checkbox input[type='checkbox']:checked + label:after,
.checkbox input[type='checkbox']:checked + span:after {
  color: $cerulean-blue !important;
  content: '\f00c' !important;
  font-family: "Font Awesome 5 Pro", sans-serif;
  position: absolute;
  top: 0;
  left: 1px;
  font-size: 12px;
}

.checkbox input[type='checkbox']:disabled + label:before {
  background-color: #f0f0f0;
}

.checkbox input[type='checkbox']:disabled + label {
  color: #6c757d;
}

.baseline label {
  display: inline-flex;

  span {
    margin-top: 4px;
  }
}

//Rememberme checkbox in Login
#rememberMe + span:before {
  position: relative !important;
}

.radiobutton input[type='radio'] + label:before,
.radiobutton input[type='radio'] + span:before {
  background-color: white;
  border: 1px solid $pinkish-grey;
  border-radius: 50%;
  content: '';
  display: inline-block;
  height: 14px;
  margin-right: 6px;
  position: relative;
  top: 2px;
  width: 14px;
}

.radiobutton input[type='radio']:checked + label:after,
.radiobutton input[type='radio']:checked + span:after {
  background-color: $cerulean-blue;
  border-radius: 50%;
  content: '';
  position: absolute;
  top: 5px;
  left: 3px;
  height: 8px;
  width: 8px;
}

.select-wrapper:after {
  color: $cerulean-blue !important;
  cursor: pointer;
  content: '\f078';
  font-family: "Font Awesome 5 Pro", sans-serif;
  font-size: 14px;
  position: absolute;
  right: 10px;
  top: 4px;
  pointer-events: none;
}

.input-group.empty > input {
  cursor: text;
}

//Style input and select arrow color on hover
.input-group i:hover,
.select-wrapper:hover::after {
  color: $dark-blue !important;
}

// end checkbox and radio button

//-----------------------------------------------------
//-----------------------------------------------------

// start toggle

.toggle {
  padding-top: 29px;
}

.toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  margin-top: 32px;
  margin-left: 15px;
  height: 14px;
  width: 23px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 7px;
  border: solid 1px $greyish;
  background-color: $white;
}

.slider:before {
  position: absolute;
  content: '';
  height: 12px;
  width: 12px;
  left: 0;
  bottom: 0;
  background-color: $white-two;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

input:checked + .slider {
  background-color: $cerulean-blue;
}

input:focus + .slider {
  box-shadow: 0 0 1px $cerulean-blue;
}

input:checked + .slider:before {
  -webkit-transform: translateX(10px);
  -ms-transform: translateX(10px);
  transform: translateX(10px);
}

/* Rounded sliders */
.slider.slider-round {
  border-radius: 34px;
}

.slider.slider-round:before {
  border-radius: 50%;
}

.toggle label {
  position: relative;
}

.toggle label {
  padding-left: 30px;
}

// end toggle

//-----------------------------------------------------
//-----------------------------------------------------

// start search

//batch-relation-search & material-flow-search
.search-batch-relation-material-flow {
  margin-right: 30px;
  width: 352px;

  input {
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.16);
    color: $warm-grey-two;
    border-color: $pinkish-grey $pinkish-grey $pinkish-grey $white;
    border-radius: 4px;
    height: 30px !important;
    padding-left: 29px;
    width: 100%;

    &::placeholder {
      font-style: italic;
      font-size: 12px;
      color: $warm-grey;
    }
  }

  i {
    color: $warm-grey;
    left: 25px;
    position: absolute;
    top: 9px;
  }
}

//overallplanning & producthistory & kpi workplace group master & workplace-status-evaluation
.toolbar {
  background-color: transparent !important;
}

.toolbar.search {
  padding-left: 10px;

  input {
    color: black;
    border: 1px solid $pinkish-grey;
    border-radius: 4px;
    font-size: 13px;
    font-style: normal;
    height: 30px;
    margin-top: 10px;
    padding-left: 23px;
    width: 230px;
  }

  i {
    left: 32px;
    top: 18px;
  }
}

.tab-toolbar {
  margin-left: 5px;
}

//Search-Field
.search-container {
  padding: 0 10px 0 0;

  input {
    background-color: white;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    border: 1px solid $pinkish-grey;
    color: $dark-grey;
    height: 30px;
    font-family: Roboto, sans-serif;
    font-size: 12px;
    padding-left: 23px;
    width: 100%;

    &::placeholder {
      font-family: Roboto, sans-serif;
      font-style: italic;
      color: $warm-grey;
      font-size: 12px;
    }
  }

  i {
    color: $warm-grey;
    left: 7px;
    top: 8px;
    position: absolute;
  }
}

.filter-area {
  max-width: 152px;
  padding: 0;

  input {
    border-color: $pinkish-grey $white $pinkish-grey $pinkish-grey;
    border-radius: 4px 0 0 4px;
    height: 30px;
    padding-left: 7px;
    width: 100%;
  }
}

// end search

//-----------------------------------------------------
//-----------------------------------------------------

// start date

.start,
.end {
  display: flex;

  label {
    color: $greyish-brown;
    font-size: 13px;
    font-style: italic;
    line-height: 26px;
    margin-right: 10px;
    margin-bottom: 0;
  }

  .input-group.align-items-center {
    input {
      width: 100%;
    }
  }

  input {
    border-radius: 4px 0 0 4px;
    border: 1px solid $pinkish-grey;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.16);
    height: 30px;
    width: 100px;

    &:focus {
      border: 1px solid $cerulean-blue !important;
      box-shadow: 0 0 3px 1px rgba($cerulean-blue, 0.5) !important;
      outline: none;
    }
  }
}

.end {
  @include respond-to(max-width, 1500px) {
    margin: 10px 0 10px 20px;
  }
  /*
  input {
    width: 103px;
  }*/
}

.small-col .end {
  @include respond-to(max-width, 2012px) {
    margin: 10px 0 10px 20px;
  }
}

// end date

//-----------------------------------------------------
//-----------------------------------------------------

// start legend

.legend {
  background-color: $white-two;
  //padding: 15px 10px 0; //Vor dem Aufräumen der Styles gab es pl & pr 10px. Noch benötigt?
  padding: 15px 0;
}

.legend p {
    color: $warm-grey-two;
    font-style: italic;
    margin: 0 30px 0 0;
}

.daytypebox {
  width: 30px;
  height: 15px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
  border-radius: 2px;
  text-align: center;
}

.empty .daytypebox {
  background-color: rgba($pinkish-grey, 0.5%);
  border: 1px solid $pinkish-grey;
  margin: 3px 5px 0 5px;

  .stroke {
    background-color: $pinkish-grey;
    height: 2px;
    width: 31px;
    -webkit-transform: translateY(6px) translateX(-1px) rotate(-24deg);
  }
}

.holiday-slash {
  background-color: $cerulean-blue;
  height: 2px;
  width: 30px;
  margin-top: 7px;
}

// end legend

//-----------------------------------------------------
//-----------------------------------------------------

// start angular material slide toggle

.mat-slide-toggle input {
  display: none;
}

// end angular material slide toggle

//-----------------------------------------------------
//-----------------------------------------------------

// start syncfusion

// multiselect
ejs-multiselect {
  &:disabled,
  [read-only] {
    background-color: $white-four;
    color: $greyish-brown;
  }

  .e-input-focus {
    border: 1px solid $cerulean-blue !important;
    outline: none !important;
    box-shadow: none !important;
  }

  .e-close-hooker {
    color: $cerulean-blue !important;
  }
}

.e-input-group {
  background-color: white;
  border: 1px solid $greyish !important;
  border-radius: 4px;
  color: black;
  font-family: $font;
  font-size: 13px;

  &:active {
    border: 1px solid $cerulean-blue;
  }
}
