@import '../node_modules/ag-grid-community/styles/ag-grid.css';
@import '../node_modules/ag-grid-community/styles/ag-theme-alpine.css';
@import "styles/variable";
@import "styles/form";
@import "styles/datetime";
@import '../node_modules/@angular/material/prebuilt-themes/indigo-pink.css';

@import 'ngx-toastr/toastr';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import '../node_modules/bootstrap/scss/_functions';
@import '../node_modules/bootstrap/scss/_variables';
@import '../node_modules/bootstrap/scss/_mixins';
/* You can add global styles to this file, and also import other style files */
html,
body {
  height: 100%;
  background-color: #ebebeb;
  overflow: hidden;
}

.canvas {
  height: 100%;
}

.head {
  height: 32px;
  justify-content: space-between;
}

.detail-headline[_ngcontent-dvo-c260] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.display-flex {
  display: inline-flex;
}

.toolbar{
  border-radius: 4px 4px 0 0;
  padding: 10px;
  margin-right: 15px;
}
.headerText{
  padding-left: 5px;
}


.tab-toolbar {
  margin-left: 5px;
}


.djs-context-pad .entry img {
  width: 100%;
}

.line{
  border-bottom: 1px solid #e5e5e5;
  margin: 0 10px;
  padding: 10px 0;
}

.warm-grey-two {
  color: #7a7a7a!important;
}


.bpp-properties-panel{
  height: 100%;
  background-color: white !important;
}

button.toolbar-date:focus {
  color: $cerulean-blue;
}

.toolbar-date-selected {
  margin-top: 10px;
  border: none;
  background-color: transparent;

  .fas,
  .far {
    display: flex !important;
    justify-content: center !important;
  }

  i,
  span {
    color: $cerulean-blue;
  }

  &:hover,
  :active,
  :focus {
    color: $cerulean-blue;
  }
}

.toolbar-date {
  background-color: transparent;
  margin-top: 10px;
  border: none;
  cursor: pointer;

  .fas,
  .far {
    display: flex !important;
    justify-content: center !important;
  }

  &:hover,
  :active,
  :focus {
    color: $cerulean-blue;
  }
}

.white-flux-bkg {
  background-color: #fff;
  box-shadow: 0 2px 6px 0 #00000029;
  border-radius: 4px;
}

.toolbar-container {
  padding: 10px 0;
}

.report-content{
  margin-top: 20px;
}
.container {
  max-width: none;
  padding-right: 8px;
  padding-left: 8px;
}

.empty-state {
  text-align: center;
  max-width: 800px;
  position: absolute;
  left: calc(50vw - 300px);
  top: calc(50vh - 300px);

  p {
    color: $greyish;
    font-size: 14px;
    width: 470px;
    font-weight: 300;
  }
}


.empty-state-row {
  .records-empty-state-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: calc(95vh - (122px + 4rem));
    justify-content: center;
    margin-top: 1%;
    width: 100%;

    img {
      height: 550px;
    }

    @media screen and (max-height: 800px) {
      img {
        height: 500px !important;
      }
    }

    @media screen and (min-height: 800px) {
      img {
        height: 500px !important;
      }
    }

    @media screen and (min-height: 1000px) {
      img {
        height: 550px !important;
      }
    }
  }
}
