@import '../styles/variable.scss';

//-----------------------------------------------------
//-----------------------------------------------------

// start calender

// start from to picker
.inline-date-picker {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
}

.inline-date-picker .form-group {
    width: 100%;
    margin-bottom: 0;
}

// end from to picker

// start shift model calendar – Removing will destroy layout

.calendar {
    background-color: white;
    border-top: 1px solid $pinkish-grey;
    border-right: 1px solid $pinkish-grey;
    border-bottom: 1px solid $pinkish-grey;
    border-left: none !important;
    border-radius: 0 4px 4px 0 !important;

    i {
        color: $cerulean-blue;
        font-size: 11px;
        padding: 7px;
    }
}

.calendar-read-only {
    i {
        color: $warm-grey;
    }

    &:hover {
        background-color: $white-four !important;
        border-color: $greyish !important;
    }
}

.calendar-container {
    overflow-y: auto;
}

.calendar-icon:before {
    content: '\f073';
}

.clock-icon:before {
    content: '\f017';
}

.striped-bkg:nth-child(even) {
    background-color: $white-two;
}

.border-bottom {
    border-bottom: 1px solid $white !important;
}

.boxcol {
    height: 40px;
}

.year {
    color: $warm-grey-two;
    font-size: 22px;
    font-weight: 500;
}

.dateField {
    width: 40px;
    display: inline-block;
    height: 40px;
}

//Buchstabe auf Farbfeld von Legende
.bookedday {
    font-size: 10px;
    position: relative;
    top: -3px;
}

//Container in Tagfeld
.booked {
    background-color: $lawn-green;
    height: 15px;
    text-align: center;
    width: 30px;
    border-radius: 2px;
    margin: 3px 5px 0 5px;
}

//Text Tage
.date {
    color: $greyish-brown;
    margin-left: 5px;
}

.weekend {
    background-color: $white-two;

    .date {
        color: $warm-grey;
    }
}

.weekday {
    font-size: 14px;
    font-weight: 500;
    margin-left: 5px;
    position: relative;
    top: 19px;
}

.weekdays:nth-child(7n-1) .weekday,
.weekdays:nth-child(7n) .weekday {
    font-weight: 300;
}

.holiday .holiday-slash {
    margin: 0 0 0 5px !important;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -html-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
-                                   supported by Chrome and Opera */
}

//ausgewähltes Feld
.selected {
    background-color: rgba($clear-blue, 0.2);
}

.today {
    border: 1px solid $cerulean-blue;
    border-radius: 2px;
}

//shiftWeeklyContainer
.monthContainer {
    min-width: 320px;

    .month {
        border-right: none;
        padding: 0;
        margin: 60px 0 -7px 0;
        font-size: 17px;
    }
}

.weekCalendarContainer .month {
    padding: 0;
    border-right: none;
}

.month {
    border-right: 1px solid $white;
    color: $greyish;
    font-family: $font;
    font-size: 14px;
    font-weight: 400;
    padding: 10px 0 10px 25px;
    width: 120px;
}

.yearbox {
    margin: 0 0 -65px 0;
}

// end shift model calendar
// END COPY PASTE MSC

.input-group-append {
    border-color: $pinkish-grey;
    border-radius: 0 4px 4px 0;
    height: 30px;
    margin: 0;
    width: 30px;

    .shadow {
        box-shadow: 0 0 2px rgba(0, 0, 0, 0.16) !important;
    }
}

.calendar {
    background-color: white;
    border-top: 1px solid $greyish;
    border-right: 1px solid $greyish;
    border-bottom: 1px solid $greyish;
    border-left: none !important;
    border-radius: 0 4px 4px 0 !important;

    i {
        color: $cerulean-blue;
        font-size: 11px;
        padding: 7px;
    }
}

.calendar-read-only {
    i {
        color: $warm-grey;
    }

    &:hover {
        background-color: $white-four !important;
        border-color: $greyish !important;
    }
}

.btn-outline-secondary:hover {
    // belongs to calendar
    background-color: white;
    border-color: $cerulean-blue;
    color: $cerulean-blue;
}

.ngb-dp-weekday {
    color: $warm-grey !important;
}

.ngb-dp-navigation-chevron {
    color: $cerulean-blue !important;
}

// end calender

//-----------------------------------------------------
//-----------------------------------------------------

// start datetime-picker

.datetime-picker {
    .responsive-toolbar {
        display: flex;
        padding: 0 10px;
        align-items: center;
        @include respond-to(max-width, 1500px) {
            display: block;
            margin-top: 10px;
        }
    }

    .small-col .responsive-toolbar {
        @include respond-to(max-width, 2012px) {
            display: block;
            margin-top: 10px;
        }
    }

    .calendar {
        border: 1px solid $pinkish-grey;
    }

    label {
        color: $greyish-brown;
        font-style: italic;
        margin-bottom: 0 !important;
    }

    .refresh-filter {
        align-items: center;
        display: flex;
        justify-content: flex-end;

        a {
            font-size: 14px;
            color: $cerulean-blue;
            font-family: $font;
        }
    }

    .toolbar-btn {
        align-items: center;
        display: flex;
        padding: 0 10px;
    }

    .col-xl-0 {
        flex: 0 0 0;
        max-width: 0;
        padding: 0 !important;
    }

    input {
        height: auto;

        &:focus,
        &:active {
            border: none;
        }
    }
}

// end datetime picker

//-----------------------------------------------------
//-----------------------------------------------------

// start custom-day

.custom-day {
    text-align: center;
    padding: 0.185rem 0.25rem;
    display: inline-block;
    height: 2rem;
    width: 2rem;
}

.custom-day.focused {
    background-color: #e6e6e6;
}

.custom-day.range,
.custom-day:hover {
    background-color: $cerulean-blue;
    color: white;
}

.custom-day.faded {
    background-color: rgba(2, 117, 216, 0.5);
}

// end custom-day

//-----------------------------------------------------
//-----------------------------------------------------

// start owl datetime calendar
owl-date-time-calendar {
    z-index: 9999 !important;
}

// end owl datetime calendar

//-----------------------------------------------------
//-----------------------------------------------------
