//Farbvariablen (benannt nach Zeplin)
// Grautöne von dunkel nach hell

$dark-grey: #222323;
$black: #3b3b3b;
$greyish-brown: #505050;
$warm-grey-two: #7a7a7a;
$grey-three: #636363;
$grey-four: #e2e0e0;
$warm-grey: #979797;
$greyish: #ababab;
$pinkish-grey: #d0d0d0;
$white: #e5e5e5;
$white-two: #f9f9f9;
$white-three: #ebebeb;
$white-four: #f0f0f0;

//blau
$dark-blue: #04438f;
$cerulean-blue: #0063da;
$clear-blue: #127eff;
$azure: #14abf6;
$sky: #7bd3ff;

//violett
$cornflower: #746ff5;
$violett: #554fe0;

//grün
$true-green: #208d00;
$lawn-green: #44b209;
$frog-green: #65b209;
$greenblue: #1ed195;

//turquoise
$flux-turquoise: #1ed195;
$greenish-cyan: #1fffa9;

//gelb
$sunflower-yellow-two: #e9d800;
$sunflower-yellow: #ffe000;

//orange
$light-orange: #f0ad4e;
$tangerine: #ff9312;
$pumpkin: #da7700;

//rot
$vermillion: #f2150a;
$bloodred: #d7140a;
$rust-red: #be0d05;
$brick-red: #950903;
$light-red: #a97c7a;
$light-red-two: #aa7c7a;
$pink: #fdd0ce;

//pink
$barbie-pink: #ff51ca;
$darkish-pink: #f92b9c;

//Schriftvariable
$font: Roboto, sans-serif;

//Mixin für Responsive
@mixin respond-to($val, $query) {
    @media ($val: $query) {
        @content;
    }
}

/* um das zu benutzen:
xy {
  xy;
  @include respond-to (max-width, 768px) {
    hier die Werte, die ab oben genannter Größe geändert werden sollen;
  }
}
*/
